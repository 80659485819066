import React from "react";
import { makeStyles } from "@material-ui/styles";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import PageTitle from "../components/PageTitle";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fade from "react-reveal/Fade";

const useStyles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(-8),
    boxShadow: theme.shadows[8],
    borderRadius: theme.borderRadius
  }
}));

const Contact = () => {
  const textProps = {
    fullWidth: true,
    variant: "outlined"
  };

  const classes = useStyles();
  return (
    <Layout>
      <SEO title="Contact" />
      <PageTitle bottomPadding>Get in Touch</PageTitle>
      <Container>
        <Fade up duration={300}>
          <Paper
            className={classes.form}
            component="form"
            action="https://formspree.io/contact@acardosi.dev"
            method="POST"
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>
                  You can use the contact form below, or you can shoot me an
                  email at{" "}
                  <a href="mailto:contact@acardosi.dev">contact@acardosi.dev</a>
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                style={{
                  width: "100%"
                }}
              >
                <TextField
                  {...textProps}
                  required
                  label="First Name"
                  name="first-name"
                />
              </Grid>
              <Grid
                item
                sm={6}
                style={{
                  width: "100%"
                }}
              >
                <TextField {...textProps} label="Last Name" name="last-name" />
              </Grid>
              <Grid item xs={12}>
                <TextField {...textProps} label="Subject" name="subject" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...textProps}
                  label="Your Email Address"
                  name="_replyto"
                  type="email"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...textProps}
                  multiline
                  rows="4"
                  label="Message"
                  name="message"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Container>
    </Layout>
  );
};

export default Contact;
